import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'

var stripe, elements

export default class extends Controller {
	static targets = [ "cardErrors", "payBtn" ]

	async connect() {
		stripe = await loadStripe(gon.stripe_key, {
			stripeAccount: gon.stripe_account,
		})
		const options = {
			clientSecret: gon.client_secret,
		}
		elements = await stripe.elements(options)

		var paymentElement = elements.create('payment', {
			business: { name: gon.company }
		})
		paymentElement.mount('#payment-element')

		paymentElement.on('change', function(event) {
			if (event.error) {
				this.cardErrorsTarget.textContent = event.error.message
			} else {
				this.cardErrorsTarget.textContent = ''
			}
		})

		this.payBtnTarget.disabled = false
	}

	submitPayment(ev) {
		ev.preventDefault()
		this.payBtnTarget.classList.add("loading")
		this.payBtnTarget.disabled = true
		var paymentMethodOptions = {}
		if (gon.cartes_bancaires && document.getElementById('card_network_default').value == 'cartes_bancaires') {
			this.paymentMethodOptions = {
				card: {
					network: 'cartes_bancaires',
				}
			}
		}
		if (gon.stage == 'general_sale') {
			stripe.confirmPayment({
				elements,
				confirmParams: {
					return_url: gon.redirect_url,
				},
			})
		} else {
			stripe.confirmSetup({
				elements,
				confirmParams: {
					return_url: gon.redirect_url,
				},
			})
		}
	}
}
